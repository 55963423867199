import { DefaultSeo as NextDefaultSeo } from 'next-seo'
import { siteSettings } from '@settings/site-settings'
import { useTranslation } from 'next-i18next'

export const DefaultSeo = () => {
  const { t: translate } = useTranslation('common')
  return (
    <NextDefaultSeo
      title={siteSettings.name}
      titleTemplate={`%s | ${translate('text-home-seo-blurb')}`}
      defaultTitle={siteSettings.name}
      description={siteSettings.description}
      openGraph={{
        type: 'website',
        locale: translate('language-locale-code'),
        site_name: translate('text-home-seo-blurb'),
      }}
      twitter={{
        handle: '@handle',
        site: '@site',
        cardType: 'summary_large_image',
      }}
      additionalMetaTags={[
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1.0',
        },
        {
          name: 'apple-mobile-web-app-capable',
          content: 'yes',
        },
        {
          name: 'theme-color',
          content: '#ffffff',
        },
      ]}
      additionalLinkTags={[
        {
          rel: 'favicon-96x96',
          href: 'icons/favicon-96x96.png',
        },
        {
          rel: 'manifest',
          href: '/manifest.json',
        },
      ]}
    />
  )
}
