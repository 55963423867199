import { productPlaceholder } from '@assets/placeholders'
import Heading from '@components/ui/heading'
import Image from '@components/ui/image'
import { DELIVERY, PICK_UP } from '@constants/index'
import { useOrderQuery } from '@framework/order/get-order'
import { getOrderProducts } from '@framework/product/get-product'
import usePrice from '@framework/product/use-price'
import { OrderItem, Product } from '@framework/types'
import { convertProduct } from '@framework/utils/convert-product'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { FC, useEffect, useState } from 'react'
export const OrderItemCard = ({
  product,
  index,
}: {
  product: OrderItem
  index: number
}) => {
  const { price: itemTotal } = usePrice({
    amount: product.base_row_total,
    currencyCode: process.env.NEXT_PUBLIC_CURRENCY_CODE || 'USD',
  })

  const itemsSection = product?.extension_attributes?.click_and_collect
    ? PICK_UP
    : DELIVERY
  return (
    <tr
      className="font-semibold border-b border-border-base last:border-b-0"
      key={product.id}
    >
      <td className="p-4">
        <div className="flex items-center">
          <Image
            src={product.image}
            alt={'item image'}
            className="rounded-md ltr:mr-3 rtl:ml-5"
            width={40}
            height={40}
            // style={{ width: 'auto' }}
          />
          <p>
            <span
              data-testid={`success_${itemsSection}_text_item_name(${index})`}
            >
              {product.name}
            </span>{' '}
            *{' '}
            <span
              data-testid={`success_${itemsSection}_text_item_qty(${index})`}
            >
              {product.qty_ordered}
            </span>
          </p>
        </div>
      </td>
      <td
        className="p-4"
        data-testid={`success_${itemsSection}_text_item_price(${index})`}
      >
        {itemTotal}
      </td>
    </tr>
  )
}

export interface orderItems {
  items: {
    [category: string]: HTMLElement[]
  }
  categoryLength: number
}
const OrderDetails: FC<{
  className?: string
  orderDetails: any
  loading: boolean
}> = ({ className = 'pt-10 lg:pt-12', orderDetails, loading }) => {
  const { t: translate } = useTranslation('common')
  const [items, setItems] = useState<orderItems>({
    items: {
      'Pickup Items': [],
      'Delivery items': [],
    },
    categoryLength: 0,
  })
  const [isOnlyPickUp, setIsOnlyPickUp] = useState(false)
  const [isPartial, setIsPartial] = useState(false)

  const {
    query: { id },
  } = useRouter()

  const setOrderItemshandler = async () => {
    let OrderItems: any = { ...items }
    let skusString = ''
    orderDetails?.orderItems?.map((product: OrderItem) => {
      skusString = skusString
        ? skusString + ',' + product.sku
        : skusString + product.sku
    })
    let products = await getOrderProducts(
      skusString,
      orderDetails?.orderItems,
      'en-CA',
    )
    OrderItems = {
      ...OrderItems,
      items: products,
      categoryLength:
        products['Delivery items'].length + products['Pickup Items'].length,
    }
    setIsOnlyPickUp(
      products['Delivery items'].length === 0 &&
        products['Pickup Items'].length > 0,
    )
    setIsPartial(
      products['Delivery items'].length > 0 &&
        products['Pickup Items'].length > 0,
    )
    setItems(OrderItems)
  }
  useEffect(() => {
    setOrderItemshandler()
  }, [])

  const { data: order, isLoading } = useOrderQuery(id?.toString()!)
  const { price: subtotal } = usePrice(
    orderDetails?.orderBaseTotalInclTax && {
      amount: orderDetails?.orderBaseTotalInclTax,
      currencyCode: process.env.NEXT_PUBLIC_CURRENCY_CODE || 'USD',
    },
  )
  const { price: subtotalWithDiscount } = usePrice(
    orderDetails?.orderBaseTotalInclTax && {
      amount:
        orderDetails?.orderBaseTotalInclTax +
        parseFloat(orderDetails?.orderBaseDiscountAmount),
      currencyCode: process.env.NEXT_PUBLIC_CURRENCY_CODE || 'USD',
    },
  )

  const { price: subtotalOnReferralRewards } = usePrice(
    orderDetails?.referralAmount && {
      amount: orderDetails?.subtotalOnReferralRewards,
      currencyCode: process.env.NEXT_PUBLIC_CURRENCY_CODE || 'USD',
    },
  )

  const { price: referralAmount } = usePrice(
    orderDetails?.referralAmount && {
      amount: orderDetails?.referralAmount,
      currencyCode: process.env.NEXT_PUBLIC_CURRENCY_CODE || 'USD',
    },
  )

  const { price: total } = usePrice({
    amount: orderDetails?.orderBaseGrandTotal,
    currencyCode: process.env.NEXT_PUBLIC_CURRENCY_CODE || 'USD',
  })

  const { price: discountAmount } = usePrice(
    orderDetails?.orderBaseDiscountAmount && {
      amount:
        orderDetails?.orderBaseGrandTotal -
        parseFloat(orderDetails?.orderBaseDiscountAmount),
      currencyCode: process.env.NEXT_PUBLIC_CURRENCY_CODE || 'USD',
    },
  )
  const { price: coldPackFee } = usePrice(
    orderDetails?.orderColdPackFee && {
      amount: orderDetails?.orderColdPackFee,
      currencyCode: process.env.NEXT_PUBLIC_CURRENCY_CODE || 'USD',
    },
  )
  const { price: orderTax } = usePrice(
    orderDetails?.orderTax && {
      amount: orderDetails?.orderTax,
      currencyCode: process.env.NEXT_PUBLIC_CURRENCY_CODE || 'USD',
    },
  )

  const { price: shipping } = usePrice(
    orderDetails?.orderBaseShippingAmount && {
      amount: orderDetails?.orderBaseShippingAmount,
      currencyCode: process.env.NEXT_PUBLIC_CURRENCY_CODE || 'USD',
    },
  )
  const { price: customerBalance } = usePrice({
    amount: orderDetails?.orderStoreCreditUsed * -1,
    currencyCode: process.env.NEXT_PUBLIC_CURRENCY_CODE || 'USD',
  })

  if (loading) return <p>Loading...</p>
  return (
    <div className={className}>
      <Heading variant="heading" className="mb-6 xl:mb-7">
        {translate('text-order-details')}:
      </Heading>
      <table className="  border-spacing-2  w-full text-sm font-semibold text-brand-dark lg:text-base">
        <tbody>
          {items.categoryLength &&
            Object.keys(items.items).map((category) => {
              if (!items.items[category].length) return null
              return (
                <>
                  <tr className="bg-fill-secondary">
                    <td className="p-4">{category}</td>
                    <td className="p-4">Price</td>
                  </tr>
                  {items.items[category]}
                </>
              )
            })}
        </tbody>
        <tfoot>
          <tr className="odd:bg-fill-secondary">
            <td className="p-4">{translate('text-sub-total')}:</td>
            <td className="p-4">
              {subtotalWithDiscount}
              {(orderDetails?.orderBaseDiscountAmount < 0 ||
                subtotalOnReferralRewards) && (
                <del className=" ml-2 text-sm text-opacity-50 md:text-15px pr-2 text-brand-dark">
                  {subtotalOnReferralRewards
                    ? subtotalOnReferralRewards
                    : subtotal}
                </del>
              )}
            </td>
          </tr>
          {!isOnlyPickUp && (
            <tr className="odd:bg-fill-secondary">
              <td className="p-4">{translate('text-shipping')}:</td>
              <td className="p-4">
                {shipping ? shipping : 'Free Shipping'}
                <span className="text-[13px] font-normal ltr:pl-1.5 rtl:pr-1.5 inline-block">
                  via {orderDetails?.shippingMethod}
                </span>
              </td>
            </tr>
          )}
          {coldPackFee && (
            <tr className="odd:bg-fill-secondary">
              <td className="p-4">{translate('text-cold-pack-fee')}:</td>
              <td className="p-4">
                {orderDetails?.orderBaseGrandTotal !== 0 && coldPackFee}
                {orderDetails?.orderBaseGrandTotal === 0 && (
                  <>
                    <> CA$0.00</>
                    <del className="ml-2 text-sm text-opacity-50 md:text-15px pr-2 text-brand-dark">
                      {coldPackFee}
                    </del>
                  </>
                )}
              </td>
            </tr>
          )}
          {orderTax && (
            <tr className="odd:bg-fill-secondary">
              <td className="p-4">{translate('text-tax')}:</td>
              <td className="p-4">{orderTax}</td>
            </tr>
          )}
          <tr className="odd:bg-fill-secondary">
            <td className="p-4">{translate('text-payment-method')}:</td>
            <td className="p-4">
              {orderDetails?.orderPaymentMethod}
              {orderDetails?.orderStoreCreditUsed &&
                orderDetails?.orderPaymentMethod !== 'Store Credit' &&
                ' + Store Credit'}
            </td>
          </tr>
          {referralAmount && (
            <tr className="odd:bg-fill-secondary text-brand-muted">
              <td className="p-4">{translate('text-referral')}</td>
              <td className="p-4">{referralAmount}</td>
            </tr>
          )}
          {orderDetails?.orderStoreCreditUsed && (
            <tr className="odd:bg-fill-secondary">
              <td className="p-4">{translate('text-store-credit-used')}:</td>
              <td className="p-4">{customerBalance}</td>
            </tr>
          )}
          <tr className="odd:bg-fill-secondary">
            <td className="p-4">{translate('text-total')}:</td>
            <td className="p-4">
              {total}
              {orderDetails?.orderBaseDiscountAmount < 0 &&
                orderDetails?.shipping_amount === 0 && (
                  <del className="ml-2 text-sm text-opacity-50 md:text-15px pr-2 text-brand-dark">
                    {discountAmount}
                  </del>
                )}
            </td>
          </tr>

          {isPartial && (
            <tr className="odd:bg-fill-secondary">
              <td className="p-4">{translate('text-pickup-address')}:</td>
              <td
                className="p-4"
                dangerouslySetInnerHTML={{
                  __html: orderDetails?.pickupAddress,
                }}
                data-testid={`success_text_pickup_address`}
              ></td>
            </tr>
          )}
          <tr className="odd:bg-fill-secondary">
            <td className="p-4">
              {isOnlyPickUp
                ? translate('text-pickup-address')
                : translate('text-shipping-address')}
              :
            </td>
            <td
              className="p-4"
              dangerouslySetInnerHTML={{
                __html: isOnlyPickUp
                  ? orderDetails?.pickupAddress
                  : orderDetails?.shippingAddress,
              }}
              data-testid={`success_text_${
                isOnlyPickUp ? 'pickup' : 'shipping'
              }_address`}
            ></td>
          </tr>
          <tr className="odd:bg-fill-secondary">
            <td className="p-4">{translate('text-billing-address')}:</td>
            <td
              className="p-4"
              dangerouslySetInnerHTML={{
                __html: orderDetails?.billingAddress,
              }}
            ></td>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

export default OrderDetails
