import { useRef, useEffect, FC, useState } from 'react'
import Swiper from 'swiper'

import Drift from 'drift-zoom'
let g_slider = null
let g_imageRef = null
let g_paneRef = null

const ModalPopupProductDetail: FC<any> = ({ gallery }) => {
  const [currentMainImage, setCurrentMainImage] = useState(gallery[0].thumbnail)
  let sliderRef: any = useRef(null)
  let imageRef = useRef(null)
  let paneRef = useRef(null)

  useEffect(() => {
    let slider = new Swiper(sliderRef, {
      slidesPerView: 1,
      //zoom:true,
      centeredSlides: true,
      spaceBetween: 30,
    })

    // slider.allowTouchMove = false;
    slider.on('slideChange', function (index) {
      // setCurrentSlide(this.activeIndex);
      if (g_imageRef && g_imageRef.length) {
        new Drift(g_imageRef[this.activeIndex], {
          paneContainer: g_paneRef,
        })
      }

      //
    })

    if (imageRef && imageRef.children.length) {
      g_imageRef = [...imageRef.children]
      g_paneRef = paneRef
      new Drift(imageRef.children[0], {
        paneContainer: paneRef,
        zoomFactor: 1.5,
      })
    }

    //     paneRef.classList.add('c-product-viewer__image-pane--active')
    g_slider = slider
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={{}} className="thumbnail-popup">
      {/* <div ref={i => imageRef = i} class="c-product-viewer__image-slide swiper-slide swiper-slide-active" data-zoom="https://www.foodservicedirect.com/media/catalog/product/0/0/00023700536723.jpg?width=1200&amp;height=1200&amp;canvas=1200:1200&amp;quality=75&amp;fit=bounds">
                                    <img src="https://www.foodservicedirect.com/media/catalog/product/0/0/00023700536723.jpg?width=650&amp;height=499&amp;canvas=650:499&amp;quality=75&amp;fit=bounds" alt="Tyson Boneless/Skinless Chicken Breast, 6 Ounce -- 26 per case." />
                                </div> */}
      <div className="c-product-image-modal_content">
        <div className="c-product-image-modal_slider overflow-hidden">
          <div className="c-product-image-modal_slider-container">
            <div
              ref={(i) => (sliderRef = i)}
              className="swiper-container relative"
            >
              <div
                ref={(i: any) => (paneRef = i)}
                className="z-10  absolute pointer-events-none left-0 top-0 w-full h-full "
              ></div>

              <div
                ref={(i: any) => (imageRef = i)}
                className="c-product-viewer__image-wrapper swiper-wrapper "
                style={{ zIndex: '0' }}
              >
                <div
                  data-zoom={currentMainImage}
                  className="mt-12 min-h-[400px] w-[861px] mr-8"
                >
                  <img
                    src={currentMainImage}
                    className="absolute w-auto h-full max-h-[300px] sm:max-h-[600px] m-auto transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
                    alt={currentMainImage.slice(0, 30)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="c-product-image-modal_preview-container overflow-x-auto flex space-x-4 py-2">
          <ul className="flex space-x-3">
            {gallery.map((image, index) => (
              <li
                className={`${
                  image?.thumbnail === currentMainImage ? 'border-brand' : ''
                } c-product-image-modal_preview-item min-w-[80px] max-w-[100px] cursor-pointer border`}
                key={index}
                onClick={() => setCurrentMainImage(image?.thumbnail)}
              >
                <img
                  src={image?.thumbnail}
                  alt={`Thumbnail ${index}`}
                  className="w-full h-auto object-contain"
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}
export default ModalPopupProductDetail
