export const API_ENDPOINTS = {
  // AUTH
  LOGIN: '/login',
  REGISTER: '/register',
  LOGOUT: '/logout',
  FORGET_PASSWORD: '/forget-password',

  // CATEGORIES
  CATEGORIES: '/categories.json',
  DIETARY: '/dietary.json',
  BRANDS: '/brands.json',

  // PRODUCTS
  PRODUCTS: '/products.json',
  PRODUCT: '/product.json',
  RELATED_PRODUCTS: '/related_products.json',
  FLASH_SELL_PRODUCTS: '/products_flash_sell.json',
  BEST_SELLER_PRODUCTS: '/products_best_seller.json',
  BEST_SELLER_GROCERY_PRODUCTS: '/products_best_seller_grocery.json',
  POPULAR_PRODUCTS: '/products_popular.json',
  COOKIES_PRODUCTS: '/products_cookies.json',
  CHIPS_PRODUCTS: '/products_chips.json',
  POPCORN_JERKY_PRODUCTS: '/products_popcorn_jerky.json',
  FRESH_VEGETABLES_PRODUCTS: '/products_fresh_vegetables.json',

  // CART
  SEARCH: '/search.json',
  ORDERS: '/orders.json',
  ORDER: '/order.json',
  ORDER_STATUS: '/order-status.json',

  // CUSTOMER
  ADDRESS: '/address.json',
  PAYMENT: '/payment.json',
  CONTACT: '/contact.json',

  // SHOP
  SHOP: '/shop.json',
  SHOPS: '/shops.json',

  // WISHLIST
  WISHLIST: '/wishlist.json',

  // CHECKOUT
  CUSTOMER_ORDERS: '/fsd/customer/orders',
}
