import { useModalAction } from '@components/common/modal/modal.context'
import Button from '@components/ui/button'
import { ROUTES } from '@utils/routes'
import cn from 'classnames'
import { useTranslation } from 'next-i18next'
import Router, { useRouter } from 'next/router'
import Image from '@components/ui/image'
import CloseButton from '@components/ui/close-button'
import { FC } from 'react'

interface ClickCollectPopupProps {}

const ClickCollectPopup: FC<ClickCollectPopupProps> = ({}) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { awraf } = router.query
  const { closeModal, openModal } = useModalAction()
  const steps = [
    {
      title: 'STEP 1',
      description: 'Add eligible pickup items to your cart and checkout.',
      icon: '/assets/images/store-location.svg',
    },
    {
      title: 'STEP 2',
      description:
        'We’ll send you an email once your order is ready to be picked up.',
      icon: '/assets/images/envelop.svg',
    },
    {
      title: 'STEP 3',
      description:
        'Come to the warehouse with your order pickup email to collect your items.',
      icon: '/assets/images/shopping-bag.svg',
    },
  ]

  function handleSignUp() {
    closeModal()
  }

  return (
    <div
      className={cn('w-full md:w-[500px] lg:w-[600px] xl:w-[650px]  relative')}
    >
      <CloseButton onClick={closeModal} />
      <div className="text-brand-dark mx-auto overflow-hidden rounded-lg bg-brand-light p-5">
        <h1 className="text-brand-dark text-lg font-bold mt-5">
          How Pickup Works
        </h1>
        <p className="text-brand-dark mt-5">
          Purchase your items online and pick them up at our warehouse when your
          order is ready!
        </p>

        {/* <h1 className=" text-lg font-bold mt-7">How it works</h1> */}
        <div className="pl-3 ">
          {steps.map((step) => (
            <div className="text-brand-dark flex items-center mt-6">
              <div
                style={{ minWidth: '70px', height: '70px' }}
                className="rounded-full bg-brand  mr-4 flex items-center justify-center"
              >
                <Image
                  width={35}
                  height={35}
                  src={step.icon}
                  alt={step.title}
                  style={{ width: 'auto' }}
                />
              </div>
              <div>
                <h1 className="text-brand-dark text-lg font-medium">
                  {step.title}
                </h1>
                <p className="text-sm -mt-1">{step.description}</p>
              </div>
            </div>
          ))}
        </div>

        <h1 className="text-brand-dark text-lg font-bold  mt-9">
          Frequently Asked Questions
        </h1>

        <h1 className="text-brand-dark font-bold mt-9">
          When can I pick up my items?
        </h1>
        <p className="text-sm  mt-2">
          Once all of your items are available to be picked up, you’ll receive
          an email from our team with pickup instructions.
        </p>

        <h1 className="text-brand-dark font-bold mt-9">
          Where do I pay for my order?
        </h1>
        <p className="text-sm mt-2">
          When placing your order online, an authorization will be placed on
          your card for the order total. Once you pick up your order, your
          payment will be captured. No payment will be collected at the
          warehouse.
        </p>

        <h1 className="text-brand-dark font-bold mt-9">
          Can I have someone else pick up my order?{' '}
        </h1>
        <p className="text-sm mt-2">
          Your “Order Ready for Pickup” email will contain a pickup QR Code.
          This email can be forwarded to whoever you would like to pick up the
          order. If the pickup person does not have the barcode, they will not
          be able to pick up the order.
        </p>

        {/* <h1 className="text-brand-dark font-medium mt-9">
          How do I redeem my Beauty Insider points and Credit Card Rewards
          points for this transaction?{' '}
        </h1>
        <p className="text-sm mt-2">
          You are welcome to redeem your points for discounts during the online
          checkout process. We support Credit Card Rewards and Beauty Insider
          Cash rewards in the checkout experience for Buy Online, Pick Up In
          Store. If you cancel your order, items were canceled due to out of
          stocks, or fail to pick up your fulfilled order in stores, your points
          will be refunded to your account. Unfortunately, we are not currently
          able to support Rouge Rewards cards for online pickup orders.
        </p>

        <h1 className="text-brand-dark font-medium mt-9">
          What is Curbside Concierge?
        </h1>
        <p className="text-sm mt-2">
          To help you get your beauty even faster, Sephora has partnered with
          Brookfield malls to offer Curbside Concierge at select Sephora
          locations. When shopping in proximity to one of these locations on
          sephora.com or the app, you will see Curbside Concierge as a pickup
          option on our product pages, in your basket, and at checkout. If
          selected as your ordering method, your purchase will be held with the
          Brookfield Curbside Concierge team near the mall’s onsite pickup
          location. Upon your arrival for pickup, you will then scan the QR code
          at a designated parking space, notifying a Brookfield employee to
          promptly meet you with your order.
        </p>

        <br />
        <a
          className="text-brand-dodgerBlue underline"
          href="https://www.sephora.com/ca/en/beauty/in-store-pick-up-faq"
        >
          View All FAQs
        </a> */}
      </div>
    </div>
  )
}

export default ClickCollectPopup
