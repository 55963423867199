export interface ICartPayload {
  cartItem: {
    sku: string
    qty: number
    vendor_id: string
    quote_id: string
    click_and_collect?: number
  }
}
export interface Item {
  id: string
  price: number
  quantity?: number
  stock?: number
  [key: string]: any
}

export interface UpdateItemInput extends Partial<Omit<Item, 'id'>> {}

export function addItemWithQuantity(
  items: Item[],
  item: Item,
  quantity: number,
) {
  if (quantity <= 0)
    throw new Error("cartQuantity can't be zero or less than zero")
  const existingItemIndex = items.findIndex(
    (existingItem) => existingItem.id === item.id,
  )

  if (existingItemIndex > -1) {
    const newItems = [...items]
    if (
      newItems[existingItemIndex].click_and_collect !== item?.click_and_collect
    ) {
      newItems[existingItemIndex].click_and_collect = item?.click_and_collect
    }
    newItems[existingItemIndex].quantity! += quantity
    return newItems
  }
  return [...items, { ...item, quantity }]
}

export function removeItemOrQuantity(
  items: Item[],
  id: Item['id'],
  quantity: number,
) {
  return items.reduce((acc: Item[], item) => {
    if (item.id === id) {
      const newQuantity = item.quantity! - quantity

      return newQuantity > 0
        ? [...acc, { ...item, quantity: newQuantity }]
        : [...acc]
    }
    return [...acc, item]
  }, [])
}
// Simple CRUD for Item
export function addItem(items: Item[], item: Item) {
  return [...items, item]
}

export function getItem(items: Item[], id: Item['id']) {
  return items.find((item) => item.id === id)
}

export function updateItem(
  items: Item[],
  id: Item['id'],
  item: UpdateItemInput,
) {
  return items.map((existingItem: any) =>
    existingItem.id === id
      ? { ...existingItem, ...item, quantity: existingItem.quantity }
      : existingItem,
  )
}

export function removeItem(items: Item[], id: Item['id']) {
  return items.filter((existingItem) => existingItem.id !== id)
}

export function inStock(items: Item[], id: Item['id']) {
  // @TODO hook-up atual inventory quantities...
  return true

  const item = getItem(items, id)
  if (item) return item['quantity']! < item['stock']!
  return false
}

export const calculateItemTotals = (items: Item[]) =>
  items.map((item) => ({
    ...item,
    itemTotal: item.price * item.quantity!,
  }))

export const calculateTotal = (items: Item[]) =>
  items.reduce((total, item) => total + item.quantity! * item.price, 0)

export const calculateTotalItems = (items: Item[]) =>
  items.reduce((sum, item) => sum + item.quantity!, 0)

export const calculateUniqueItems = (items: Item[]) => items.length

export interface IShppingInfoPayload {
  postcode: string | null | undefined
  email?: string
  country_id: string
  city?: string
  firstname?: string
  lastname?: string
  region_code?: string
  street?: string[]
  telephone?: string
  extension_attributes?: object
  region?: string
  saveInAddressBook?: number
}

export interface IShippingMethod {
  carrier_code: string
  method_code: string
  carrier_title: string
  method_title: string
  amount: number
  extension_attributes?: {
    expected_delivery_date?: string
    estimated_transit_days?: number
  }
}
