export interface ContactFormValues {
  email: string
  customerType: string
}

export interface Email {
  email: string
}

export interface ShippingFormValues {
  email: any
  firstName: string
  lastName: string
  companyName: string
  phoneNumber: string
  addressLineOne: string
  addressLineTwo: string
  city: string
  zipCode: string
  deliveryInstruction: string
  region: string
  region_code: string
  region_id: string
}

export interface CreditCardFormValues {
  name: string
  number: string
  expirationDate: string
  cvv: string
}

export interface IsNewAddress {
  shipping: boolean
  billing: boolean
  isEditAddress: boolean
}

export const initialContactInfo: ContactFormValues = {
  email: '',
  customerType: '',
}

export const initialShippingInfo: ShippingFormValues = {
  email: '',
  firstName: '',
  lastName: '',
  companyName: '',
  phoneNumber: '',
  addressLineOne: '',
  addressLineTwo: '',
  city: '',
  zipCode: '',
  deliveryInstruction: '',
  region: '',
  region_code: '',
  region_id: '',
}

export const initialPaymentInfo: CreditCardFormValues = {
  name: '',
  number: '',
  expirationDate: '',
  cvv: '',
}

export interface PickupAddress {
  region: {
    region_code: string
    region_name: string
  }
  country: {
    country_code: string
    country_name: string
  }
  address_details: {
    state: string
    city: string
    postal_code: string
    address_line_1: string
    address_line_2: string
    first_name: string
    last_name: string
    phone: string
  }
  store_name: string
}

interface Settings {
  environment: string
  cart: {
    free_shipping: string
    hide_other_shipping_methods: boolean
  }
  authorizeNet: {
    clientKey: string
    apiLoginID: string
  }
  search: {
    type: string
    autoSuggestUrl: string | null
    hasCategoryFilter: boolean
    resultUrl: string
  }
  paypal: {
    environment: string
  }
  storeCredit: {
    isActive: boolean
  }
  store: {
    storeName: string
    storeId: string
    storeCode: string
  }
  form_key: string
  isRecSysAPIEnabled: string
  product_notification: null
  liquidation_styles: null
  isGAEnabled: string
  isUPSAddressValidatorEnabled: string
  isBulkPricingRequestFormEnabled: string
  isIP2LocationEnabled: string
  isDeliveryDateProcessorEnabled: string
  ltlShippingInfo: {
    id: string
    name: string
    alert_message: string
  }
  defaultPostalCode: string
  pickupAddress: {
    region: {
      region_code: string
      region_name: string
    }
    country: {
      country_code: string
      country_name: string
    }
    address_details: {
      first_name: string
      last_name: string
      phone: string
      state: string
      city: string
      postal_code: string
      address_line_1: string
      address_line_2: string
    }
    store_name: string
  }
  clickAndCollectDetails: {
    isIpRestrictionEnabled: string
    clientIp: string
    allowedAddressBasedOn: string
    allowedAddresses: string
    clientIpAddress: string
    showClickAndCollect: boolean
  }
}

export interface Config {
  settings: Settings
}

export enum CheckoutSteps {
  'SHIPPING_ADDRESS' = 1,
  'SHIPPING_METHODS' = 2,
  'PAYMENT_METHODS' = 3,
}
