import { useModalAction } from '@components/common/modal/modal.context'
import cn from 'classnames'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'
import ProductTitle from './product-title'
import { RiCloseLine } from 'react-icons/ri'

import ModalPopupProductDetail from '@components/ui/carousel/thumbnail-carousel-zoom'

const ThumbnailPopup: FC<any> = ({ product, gallery }) => {
  const { t } = useTranslation()
  const { closeModal } = useModalAction()

  return (
    <div
      className={cn('w-full md:w-[500px] lg:w-[600px] xl:w-[650px]  relative')}
    >
      <div className="bg-brand-light p-2 sm:p-8 pt-14">
        <p className="absolute right-2 sm:right-5 top-2 sm:top-4  cursor-pointer">
          <RiCloseLine onClick={closeModal} fontSize={25} />
        </p>

        <ProductTitle
          product={product}
          className="leading-5 text-lg text-center"
        />
        <div className="">
          <ModalPopupProductDetail gallery={gallery} />
        </div>
      </div>
    </div>
  )
}

export default ThumbnailPopup
