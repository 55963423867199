const POST = {
  CUSTOMER_LOGIN: '/integration/customer/token',
  CUSTOMER_LOGIN_WITH_CARTID: '/fsd/customer/login',
  REFERRAL_QOUTE: '/awRafQuote',

  // Cart
  CREATE_CART_GUEST: '/guest-carts',
  CREATE_CUSTOMER_CART: '/carts/mine',
  CUSTOMER_CART: '/fsd/carts/mine',
  ADD_TO_CART: '/cartitems/mine/items',
  GUEST_CART: `/fsd/guest-carts/{cart-id}`,
  DELETE_GUEST_CART: `/fsd/guest-carts/{cart-id}/sku/{itemId}`,
  DELETE_GUEST_ALL_CART: `/fsd/guest-carts/{cart-id}/items`,
  UPDATE_GUEST_CART: `/fsd/guest-carts/{cart-id}/items/{itemId}`,
  DELETE_CUSTOMER_CART: `/fsd/carts/sku/{itemId}`,
  DELETE_CUSTOMER_ALL_CART: `/fsd/carts/mine/items`,
  UPDATE_CUSTOMER_CART: `/fsd/carts/mine/items/{itemId}`,

  //Newsletter
  NEWSLETTER: '/newsletter/subscriber/email',

  // PLP
  CATEGORY_FILTERS: '/fsd/category-filters',
  CATEGORY_BREADCRUMBS: '/fsd/category-breadcrumb',
}
export default POST
