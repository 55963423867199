import { Attachment, Product } from '@framework/types'

export const convertProduct = (document: any, locale?: any) => {
  // @TODO...

  if (document?.gallery && document.gallery?.length > 0) {
    const usable_imgs: any[] = []
    const used_ids = []

    document.gallery.map((img: any) => {
      const file_type = String(img.original).substring(
        String(img.original).length - 3,
      )
      const gs1_id = String(img.id).substring(0, String(img.id).indexOf('_'))
      const isFrench = String(img.original).indexOf('_fr.') !== -1
      const isEnglish = String(img.original).indexOf('_en.') !== -1
      if (file_type === 'jpg' || file_type === 'png' || file_type === 'gif') {
        // if (locale && locale === 'fr-CA') {
        //   if (!isEnglish) usable_imgs.push(img);
        // } else if (locale && locale === 'en-CA') {
        //   if (!isFrench) usable_imgs.push(img);
        // } else {
        usable_imgs.push(img)
        // }
      }
      used_ids.push(gs1_id)
    })
    document.gallery = usable_imgs
  }

  return document

  if (typeof document === 'undefined') return [] as Product[]
  const images: Attachment[] = []
  document.mag_media_gallery_entries?.map((image: any) => {
    images.push({
      id: image.id,
      thumbnail: `https://www.foodservicedirect.com/media/catalog/product/${image.file}`,
      original: `https://www.foodservicedirect.com/media/catalog/product/${image.file}`,
    })
  })

  const product = {
    id: document.id,
    name: document.mag_name,
    slug: `${document.mag_attributes?.url_key}.html`,
    price: document.oms_price,
    quantity: document.mag_attributes?.unit_amount,
    sold: 99,
    unit: document.mag_attributes?.unit_amount_type,
    // sale_price?: 9.99,
    // min_price?: 9.99,
    // max_price?: 9.99,
    image: {
      id: document.id,
      thumbnail: `https://www.foodservicedirect.com/media/catalog/product/${document.mag_attributes?.thumbnail}`,
      original: `https://www.foodservicedirect.com/media/catalog/product/${document.mag_attributes?.image}`,
    },
    sku: document.oms_pid,
    gallery: images,
    // category?: Category,
    // tag?: Tag[],
    // meta?: any[],
    // brand?: Brand,
    description: document.mag_attributes?.description,
    // variations?: object,
    // [key: string]: unknown,
  }

  return product
}
