import { useTranslation } from 'react-i18next'

type ProductItem = {
  brand?: string
  name?: string
  attributes?: {
    case_description?: string
  }
  case_quantity?: number
}

export const CommonProductTitle = (item: ProductItem): string => {
  const { t: translate } = useTranslation('common')

  if (!item) return ''

  const brandPart =
    item.brand && item.name && !item.name.includes(item.brand)
      ? `${item.brand} `
      : ''

  const namePart = item.name ? `${item.name} ` : ''

  const caseDescriptionPart = item.attributes?.case_description
    ? `(${item.attributes.case_description}) `
    : ''

  const caseQuantityPart = item.case_quantity
    ? `${item.case_quantity} ${translate('text-per-case')}`
    : ''

  return `${brandPart}${namePart}${caseDescriptionPart}${caseQuantityPart}`.trim()
}
