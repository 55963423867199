import { UKFlag } from '@components/icons/language/UKFlag'
import { QCFlag } from '@components/icons/language/QCFlag'
// import { CAFlag } from '@components/icons/language/CAFlag';
import siteLogo from '@assets/images/fsd_logo_canada.svg'
import Image from 'next/image'

export const siteSettings = {
  name: "FoodServiceDirect.ca - Canada's Widest Variety in Food Service!",
  description:
    'FoodServiceDirect is your source for restaurant supplies, dry foods, refrigerated & frozen foods, paper products, and all other restaurant needs including disposable & janitorial products.',
  author: {
    name: 'FoodServiceDirect',
    websiteUrl: 'https://www.foodservicedirect.ca',
    address: '',
  },
  logo: {
    url: siteLogo,
    alt: "FoodServiceDirect.ca - Canada's Widest Variety in Food Service!",
    href: '/',
    width: 128,
    height: 30,
  },
  defaultLanguage: 'en-CA',
  currencyCode: 'CAD',
  canonical: 'https://www.foodservicedirect.ca/',
  base_url: 'https://www.foodservicedirect.ca/',
  site_header: {
    menu: [
      // {
      //   id: 1,
      //   path: '/',
      //   label: 'menu-demos',
      //   subMenu: [
      //     {
      //       id: 1,
      //       path: '/',
      //       label: 'menu-modern',
      //     },
      //     {
      //       id: 2,
      //       path: '/classic',
      //       label: 'menu-classic',
      //     },
      //     {
      //       id: 3,
      //       path: '/vintage',
      //       label: 'menu-vintage',
      //     },
      //     {
      //       id: 4,
      //       path: '/standard',
      //       label: 'menu-standard',
      //     },
      //     {
      //       id: 5,
      //       path: '/minimal',
      //       label: 'menu-minimal',
      //     },
      //     {
      //       id: 6,
      //       path: '/trendy',
      //       label: 'menu-trendy',
      //     },
      //     {
      //       id: 7,
      //       path: '/elegant',
      //       label: 'menu-elegant',
      //     },
      //     {
      //       id: 8,
      //       path: '/refined',
      //       label: 'menu-refined',
      //     },
      //     {
      //       id: 9,
      //       path: '/antique',
      //       label: 'menu-antique',
      //     },
      //     {
      //       id: 10,
      //       path: '/ancient',
      //       label: 'menu-ancient',
      //     },
      //   ],
      // },
      // {
      //   id: 2,
      //   path: '/search',
      //   label: 'menu-categories',
      //   subMenu: [
      //     {
      //       id: 1,
      //       path: '/search',
      //       label: 'menu-fresh-vegetables',
      //     },
      //     {
      //       id: 2,
      //       path: '/search',
      //       label: 'menu-diet-nutrition',
      //     },
      //     {
      //       id: 3,
      //       path: '/search',
      //       label: 'menu-healthy-foods',
      //     },
      //     {
      //       id: 4,
      //       path: '/search',
      //       label: 'menu-grocery-items',
      //     },
      //     {
      //       id: 5,
      //       path: '/search',
      //       label: 'menu-beaf-steak',
      //     },
      //   ],
      // },
      // {
      //   id: 3,
      //   path: '/search',
      //   label: 'menu-dietary',
      //   subMenu: [
      //     {
      //       id: 1,
      //       path: '/search',
      //       label: 'menu-vegetarian',
      //     },
      //     {
      //       id: 2,
      //       path: '/search',
      //       label: 'menu-kakogenic',
      //     },
      //     {
      //       id: 3,
      //       path: '/search',
      //       label: 'menu-mediterranean',
      //     },
      //     {
      //       id: 4,
      //       path: '/search',
      //       label: 'menu-organic',
      //     },
      //   ],
      // },
      // {
      //   id: 4,
      //   path: '/search/',
      //   label: 'menu-search',
      // },
      // {
      //   id: 5,
      //   path: '/shops/',
      //   label: 'menu-shops',
      // },
      // {
      //   id: 6,
      //   path: '/',
      //   label: 'menu-pages',
      //   subMenu: [
      //     {
      //       id: 1,
      //       path: '/',
      //       label: 'menu-users',
      //       subMenu: [
      //         {
      //           id: 1,
      //           path: '/my-account/account-settings',
      //           label: 'menu-my-account',
      //         },
      //         {
      //           id: 2,
      //           path: '/signin',
      //           label: 'menu-sign-in',
      //         },
      //         {
      //           id: 3,
      //           path: '/signup',
      //           label: 'menu-sign-up',
      //         },
      //       ],
      //     },
      {
        id: 2,
        path: '/faq',
        label: 'menu-faq',
      },
      {
        id: 3,
        path: '/about-us',
        label: 'menu-about-us',
      },
      {
        id: 4,
        path: '/privacy',
        label: 'menu-privacy-policy',
      },
      {
        id: 5,
        path: '/terms',
        label: 'menu-terms-condition',
      },
      {
        id: 6,
        path: 'https://support.foodservicedirect.com/hc/en-us/requests/new',
        label: 'menu-contact-us',
      },
      {
        id: 7,
        path: '/track-order',
        label: 'menu-track-order',
        //   },
        // ],
      },
    ],
    languageMenu: [
      // {
      //   id: 'ar',
      //   name: 'عربى - AR',
      //   value: 'ar',
      //   icon: <SAFlag />,
      // },
      // {
      //   id: 'zh',
      //   name: '中国人 - ZH',
      //   value: 'zh',
      //   icon: <CNFlag />,
      // },
      {
        id: 'en-CA',
        name: '- EN',
        value: 'en-CA',
        icon: (
          <Image
            src="/assets/images/ca_flag.svg"
            alt="English Site"
            width={23}
            height={19}
          />
        ),
      },
      {
        id: 'fr-CA',
        name: '- FR',
        value: 'fr-CA',
        icon: <QCFlag />,
      },
      // {
      //   id: 'he',
      //   name: 'rעברית - HE',
      //   value: 'he',
      //   icon: <ILFlag />,
      // },
      // {
      //   id: 'es',
      //   name: 'Español - ES',
      //   value: 'es',
      //   icon: <ESFlag />,
      // },
    ],
    pagesMenu: [
      {
        id: 1,
        path: '/search',
        label: 'menu-best-deals',
      },
      {
        id: 2,
        path: '/about-us',
        label: 'menu-about-us',
      },
      {
        id: 3,
        path: '/contact-us',
        label: 'menu-contact-us',
      },
      {
        id: 4,
        path: '/faq',
        label: 'menu-faq',
      },
    ],
  },
}
