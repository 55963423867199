import { useTranslation } from 'next-i18next'
import cn from 'classnames'
import { CommonProductTitle } from '@utils/common-product-title'
export default function ProductTitle({
  product,
  className = 'text-lg md:text-xl xl:text-2xl',
}: any) {
  const { t } = useTranslation('common')
  return (
    <div className="md:mb-2.5 block -mt-1.5">
      <h2
        className={cn(
          'text-left font-medium transition-colors duration-300 text-brand-dark capitalize',
          className,
          'text-[24px] md:text-[24px] md:leading-7 md:pt-5 ', // lg breakpoint for screens 1024px and up
        )}
      >
        {CommonProductTitle(product)}
      </h2>
    </div>
  )
}
