import { FC, SVGAttributes } from 'react'

export const QCFlag: FC<SVGAttributes<{}>> = ({ ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 543.861 362.571"
      transform="scale(1)"
      className="h-full"
    >
      <rect
        id="rect556"
        fillRule="evenodd"
        height="362.57"
        width="543.86"
        y=".00052"
        x="0"
        strokeWidth="1pt"
        fill="#fff"
      />
      <g id="Layer_1" fillRule="evenodd" fill="#0d2b88">
        <path
          id="path550"
          d="m430.56 249.27c2.46 2.75 4.6 5.31 6.32 8.16 4.79 7.93 6.41 16.55 3.73 23.52-5.33 14.74-7.37 18.49-7.37 30.23h3.78c0.24-12.92 5.11-21.56 12.35-24.16 3.18-1.23 7.31-0.62 11.1 3.28 4.69 4.82 6.05 18.11-7.71 20.67 1.46-2.17 1.34-6.91-1.58-8.59-2.23-1.11-4.74-0.67-5.92 0.28-1.89 1.15-3.71 4.81-3.71 8.52l6.53-0.01v7.3l-13.56-0.01c0.17 3.75 2.22 6.76 6.03 8.44-0.49 1.58-2.79 4.8-5.94 4.58-0.62 3.41-1.22 5.33-4.05 8.43-2.84-3.1-3.43-5.02-4.05-8.43-3.16 0.22-5.46-3-5.95-4.58 3.81-1.68 5.86-4.69 6.04-8.44l-13.57 0.01v-7.3l6.54 0.01c0-3.71-1.83-7.37-3.72-8.52-1.18-0.95-3.69-1.39-5.92-0.28-2.91 1.68-3.04 6.42-1.58 8.59-13.76-2.56-12.4-15.85-7.71-20.67 3.79-3.9 7.92-4.51 11.1-3.28 7.24 2.6 12.11 11.24 12.35 24.16h3.78c0-11.74-2.04-15.49-7.36-30.23-2.69-6.97-1.07-15.59 3.72-23.52 1.72-2.85 3.86-5.41 6.33-8.16zm-113.31-22.66h226.61v135.96h-226.61v-135.96z"
        />
        <path
          id="path551"
          d="m430.56 22.661c2.46 2.75 4.6 5.316 6.32 8.16 4.79 7.935 6.41 16.551 3.73 23.521-5.33 14.747-7.37 18.49-7.37 30.232h3.78c0.24-12.917 5.11-21.563 12.35-24.159 3.18-1.229 7.31-0.62 11.1 3.278 4.69 4.824 6.05 18.114-7.71 20.67 1.46-2.166 1.34-6.909-1.58-8.583-2.23-1.114-4.74-0.678-5.92 0.28-1.89 1.145-3.71 4.806-3.71 8.514l6.53-0.009v7.298l-13.56-0.008c0.17 3.754 2.22 6.761 6.03 8.435-0.49 1.58-2.79 4.81-5.94 4.59-0.62 3.41-1.22 5.32-4.05 8.42-2.84-3.1-3.43-5.01-4.05-8.42-3.16 0.22-5.46-3.01-5.95-4.59 3.81-1.674 5.86-4.681 6.04-8.435l-13.57 0.008v-7.298l6.54 0.009c0-3.708-1.83-7.369-3.72-8.514-1.18-0.958-3.69-1.394-5.92-0.28-2.91 1.674-3.04 6.417-1.58 8.583-13.76-2.556-12.4-15.846-7.71-20.67 3.79-3.897 7.92-4.507 11.1-3.278 7.24 2.595 12.11 11.242 12.35 24.159h3.78c0-11.742-2.04-15.486-7.36-30.232-2.69-6.97-1.07-15.586 3.72-23.521 1.72-2.844 3.86-5.411 6.33-8.16zm-113.31-22.661h226.61v135.96h-226.61v-135.96z"
        />
        <path
          id="path552"
          d="m113.31 249.27c2.46 2.75 4.6 5.31 6.32 8.16 4.79 7.93 6.41 16.55 3.73 23.52-5.33 14.74-7.37 18.49-7.37 30.23h3.78c0.24-12.92 5.11-21.56 12.35-24.16 3.18-1.23 7.31-0.62 11.1 3.28 4.69 4.82 6.05 18.11-7.71 20.67 1.46-2.17 1.33-6.91-1.58-8.59-2.23-1.11-4.74-0.67-5.92 0.28-1.89 1.15-3.71 4.81-3.71 8.52l6.53-0.01v7.3l-13.56-0.01c0.17 3.75 2.22 6.76 6.03 8.44-0.49 1.58-2.79 4.8-5.94 4.58-0.62 3.41-1.22 5.33-4.05 8.43-2.84-3.1-3.43-5.02-4.06-8.43-3.15 0.22-5.45-3-5.94-4.58 3.81-1.68 5.86-4.69 6.04-8.44l-13.568 0.01v-7.3l6.538 0.01c0-3.71-1.82-7.37-3.72-8.52-1.176-0.95-3.691-1.39-5.918-0.28-2.914 1.68-3.039 6.42-1.582 8.59-13.762-2.56-12.396-15.85-7.709-20.67 3.789-3.9 7.92-4.51 11.105-3.28 7.234 2.6 12.104 11.24 12.344 24.16h3.78c0-11.74-2.04-15.49-7.36-30.23-2.68-6.97-1.07-15.59 3.72-23.52 1.72-2.85 3.86-5.41 6.33-8.16zm-113.31-22.66h226.61v135.96h-226.61v-135.96z"
        />
        <path
          id="path553"
          d="m113.31 22.661c2.46 2.75 4.6 5.316 6.32 8.16 4.79 7.935 6.41 16.551 3.73 23.521-5.33 14.747-7.37 18.49-7.37 30.232h3.78c0.24-12.917 5.11-21.563 12.35-24.159 3.18-1.229 7.31-0.62 11.1 3.278 4.69 4.824 6.05 18.114-7.71 20.67 1.46-2.166 1.33-6.909-1.58-8.583-2.23-1.114-4.74-0.678-5.92 0.28-1.89 1.145-3.71 4.806-3.71 8.514l6.53-0.009v7.298l-13.56-0.008c0.17 3.754 2.22 6.761 6.03 8.435-0.49 1.58-2.79 4.81-5.94 4.59-0.62 3.41-1.22 5.32-4.05 8.42-2.84-3.1-3.43-5.01-4.06-8.42-3.15 0.22-5.45-3.01-5.94-4.59 3.81-1.674 5.86-4.681 6.04-8.435l-13.568 0.008v-7.298l6.538 0.009c0-3.708-1.82-7.369-3.72-8.514-1.176-0.958-3.691-1.394-5.918-0.28-2.914 1.674-3.039 6.417-1.582 8.583-13.762-2.556-12.396-15.846-7.709-20.67 3.789-3.897 7.92-4.507 11.105-3.278 7.234 2.595 12.104 11.242 12.344 24.159h3.78c0-11.742-2.04-15.486-7.36-30.232-2.68-6.97-1.07-15.586 3.72-23.521 1.72-2.844 3.86-5.411 6.33-8.16zm-113.31-22.661h226.61v135.96h-226.61v-135.96z"
        />
      </g>
    </svg>
  )
}
