import { useContext, useEffect, useState } from 'react'
import { apiRestInstance } from '@utils/axios'
import { getToken } from '@framework/utils/get-token'
import { getSessionStore } from '@utils/helper'
interface IRestParams {
  url: string
  method?: 'get' | 'post' | 'delete' | 'put'
  onSuccessMessage?: string
  onErrorMessage?: string
  onComplete?: () => void
  onError?: () => void
}
export type TAPIHookLazy = [
  (body: any) => Promise<void>,
  { data: any; isLoading: any; error: any; body: any },
]
export type TAPIHook = { data: any; isLoading: any; error: any; body?: any }

export const useLazyRestApi = ({
  url,
  method,
  onSuccessMessage,
  onErrorMessage,
  onComplete,
  onError,
}: IRestParams): TAPIHookLazy => {
  const [isLoading, setLoading] = useState<any>(undefined)
  const [data, setData] = useState<any>(null)
  const [body, setBody] = useState<any>(null)
  const [error, setError] = useState<any>(null)
  const apiTrigger = async (body?: any) => {
    setLoading(true)
    try {
      const result: any = await callRestApi(url, method, body)

      setData(result)
      if (result?.message && !result?.status) {
        throw Error(result?.message)
      }
      if (onComplete) onComplete()
      setLoading(false)
      setError(null)
    } catch (err: any) {
      setBody(body)
      if (err.toString() || onErrorMessage) setLoading(false)
      setError(err)
      setData(null)
      if (onError) onError()
    }
  }
  const returnData = { data, isLoading, error, body }
  return [apiTrigger, returnData]
}
export const useRestApi = ({
  url,
  method = 'get',
  onSuccessMessage,
  onErrorMessage,
  onComplete,
  onError,
}: IRestParams): TAPIHook => {
  const [isLoading, setLoading] = useState<any>(undefined)
  const [data, setData] = useState<any>(null)
  const [error, setError] = useState<any>(null)
  const apiTrigger = async (body: any) => {
    setLoading(true)
    try {
      const result = await callRestApi(url, method, body)
      setData(result)
      setLoading(false)
      setError(null)
      if (onComplete) onComplete()
    } catch (ex) {
      setLoading(false)
      setError(ex)
      setData(null)
      if (onError) onError()
    }
  }
  useEffect(() => {
    apiTrigger(undefined)
  }, [])

  const returnData = { data, isLoading, error }
  return returnData
}

export const callRestApi = async (
  url: string,
  method: 'get' | 'post' | 'delete' | 'put' = 'get',
  body = null,
  token: null | string = null,
) => {
  // const {data} = useSession();

  // eslint-disable-next-line no-useless-catch
  try {
    const session: any = getSessionStore()
    token = token || session?.token
    if (url.includes('/{cart-id}')) {
      url = url.replace('/{cart-id}', '/' + session?.cart)
    }
    if (body && Object.keys(body).length) {
      Object.entries(body).find(([key, value]) => {
        if (url.includes('/{' + key)) {
          url = url.replace('/{' + key + '}', '/' + value)
          delete body[key]
          return true
        }
      })
    }
    const result = await apiRestInstance().request({
      url,
      method,
      data: body,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return result
  } catch (ex) {
    throw ex
  }
}
