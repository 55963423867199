import SETTINGS from '@constants/index'
import { ShippingFormValues } from '@contexts/checkout/checkout.utils'
import { sendGTMEvent } from '@next/third-parties/google'

interface Product {
  id: string
  formattedName: string
  category: string
  brand: string
  price: number
  quantity?: number
  category_l1?: string
  category_l2?: string
  category_l3?: string
  category_l4?: string
}

interface Transaction {
  id: string
  revenue: number
  tax?: number
  shipping?: number
  coupon?: string
}

interface Payload {
  transaction_id?: string
  currency: string | undefined
  value: number
  items: any[]
  tax?: number
  shipping?: number
  coupon?: string
}

interface UserDataPayload {
  event: string
  email: string
  phone: string
  first_name: string
  last_name: string
  street: string
  city: string
  region: string
  country: string
  postal_code: string
}

const buildPayload = (
  products: Product[],
  value: number,
): Omit<Payload, 'transaction_id' | 'tax' | 'shipping'> => {
  return {
    currency: process.env.NEXT_PUBLIC_CURRENCY_CODE || '',
    value,
    items: products.map((product) => ({
      item_id: product?.id,
      item_name: product?.formattedName,
      item_category: product?.category,
      item_category2: product?.category_l2,
      item_category3: product?.category_l3,
      item_category4: product?.category_l4,
      item_brand: product?.brand,
      quantity: product?.quantity || 1,
      price: product?.price,
    })),
  }
}

const viewProduct = (products: Product[]) => {
  try {
    const value = products.reduce((total, product) => total + product.price, 0)
    const viewProductPayload: Payload = buildPayload(products, value)

    sendGTMEvent({ event: 'view_item', ecommerce: viewProductPayload })
  } catch (err) {
    console.error('GA4 View Item Error :', err)
  }
}

const addToCart = (products: Product[]) => {
  try {
    const value = products.reduce(
      (total, product) => total + product.price * (product.quantity || 1),
      0,
    )
    const addToCartPayload: Payload = buildPayload(products, value)

    sendGTMEvent({ event: 'add_to_cart', ecommerce: addToCartPayload })
  } catch (err) {
    console.error('GA4 Add to cart Error :', err)
  }
}

const beginCheckout = (products: Product[]) => {
  try {
    const value = products.reduce(
      (total, product) => total + product.price * (product.quantity || 1),
      0,
    )
    const beginCheckoutPayload: Payload = buildPayload(products, value)

    sendGTMEvent({ event: 'begin_checkout', ecommerce: beginCheckoutPayload })
  } catch (err) {
    console.error('GA4 Add to cart Error :', err)
  }
}

const purchase = (products: Product[], transaction: Transaction) => {
  try {
    const value = Number(transaction.revenue)
    const purchasePayload: Payload = {
      ...buildPayload(products, value),
      transaction_id: transaction.id,
      tax: Number(transaction.tax),
      shipping: Number(transaction.shipping),
      coupon: transaction.coupon,
    }

    sendGTMEvent({ event: 'purchase', ecommerce: purchasePayload })
  } catch (err) {
    console.error('GA4 Purchase Error :', err)
  }
}

const userData = (shipping_address: ShippingFormValues, contactData: any) => {
  const {
    phoneNumber,
    firstName,
    lastName,
    addressLineOne,
    city,
    region,
    zipCode,
  } = shipping_address
  const payload: UserDataPayload = {
    event: 'User_Data',
    email: contactData?.email,
    phone: phoneNumber,
    first_name: firstName,
    last_name: lastName,
    street: addressLineOne,
    city,
    region,
    country: SETTINGS.shipping.DEFAULT_COUNTRY_ID,
    postal_code: zipCode,
  }

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(payload)
}

const ga4Services = {
  viewProduct,
  addToCart,
  beginCheckout,
  purchase,
  userData,
}

export default ga4Services
