import SETTINGS, { CART_STATUS } from '@constants/index'
import { IShppingInfoPayload, Item } from '@contexts/cart/cart.utils'
import { useLazyRestApi } from '@hooks/useRestApi'
import cartServices from '@services/cartservices'
import ENDPOINT from '@utils/restendpoints'
import { createContext, useEffect, useState } from 'react'
import { IUser } from './user.utils'
import { getSession, signIn, useSession } from 'next-auth/react'
import { getProductsBySkus } from '@framework/product/get-product'
import { useModalAction } from '@components/common/modal/modal.context'
import { getSessionStore } from '@utils/helper'

export const UserContext = createContext<IUser | null>(null)

let defaultShipping: IShppingInfoPayload = {
  postcode: cartServices.getSelectedPostalCode()
    ? cartServices.getSelectedPostalCode()
    : SETTINGS.shipping.DEFAULT_POST_CODE,
  region: SETTINGS.shipping.DEFAULT_REGION,
  country_id: SETTINGS.shipping.DEFAULT_COUNTRY_ID,
  extension_attributes: { address_type: SETTINGS.shipping.ADDRESS_TYPE },
}

export const UserProvider = ({ children }: { children: JSX.Element }) => {
  const [isEmpty, setEmpty] = useState(true)
  const [cartStatus, setCartStatus] = useState(CART_STATUS.EMPTY)
  const [cartLoader, setCartLoader] = useState(false)
  const [cart, setCart] = useState({})
  const [isGuest, setGuest] = useState(true)
  const [createCart, setCreateCart] = useState(false)
  const [loggingIn, setLoggingIn] = useState(false)
  const [logInUserItems, setLogInUserItems] = useState<Item[]>([])
  const { data: session } = useSession()
  const [sameAsShipping, setSameAsShipping] = useState(true)
  const [magConfig, setMagConfig] = useState(true)
  const [regions, setRegions] = useState([])
  const [addresses, setAddresses] = useState<any>(null)
  const [shippingInformation, setShippingInformation] =
    useState<IShppingInfoPayload>(defaultShipping)
  const [billingInformation, setBillingInformation] =
    useState<IShppingInfoPayload>(defaultShipping)
  const [userApi, { data: user }] = useLazyRestApi({ url: ENDPOINT.USER })
  const [regionApi, { data: country_regions }] = useLazyRestApi({
    url: ENDPOINT.REGIONS,
  })
  const { openModal } = useModalAction()
  const [createCustomerCart] = useLazyRestApi({
    url: ENDPOINT.CREATE_CUSTOMER_CART,
    method: 'post',
  })

  const [setReferralRewardsQoute, { data: referalData, error: referralError }] =
    useLazyRestApi({
      url: ENDPOINT.REFERRAL_QOUTE,
      method: 'put',
      onComplete: () => {
        localStorage.removeItem('referral_token')
        openModal('REFERRAL_START_SHOPPING', {
          ref_amount: localStorage.getItem('ref_amount'),
        })
        localStorage.removeItem('ref_amount')
      },
      onError: () => {
        console.error('setReferralRewardsQoute Error', referralError)
      },
    })

  const [createGuestCart, { data: cartid }] = useLazyRestApi({
    url: ENDPOINT.CREATE_CART_GUEST,
    method: 'post',
  })
  const [getCustomerCart, { data: customerCart }] = useLazyRestApi({
    url: ENDPOINT.CUSTOMER_CART,
  })
  const [getGuestCart, { data: guestCart }] = useLazyRestApi({
    url: ENDPOINT.GUEST_CART,
  })

  useEffect(() => {
    if (session?.token && !session?.isGuest) {
      createCustomerCart(null)
      userApi(null)
      getCart()
    }
  }, [session])
  const getProducts = async (skusString: string, customerItems: any) => {
    let items = await getProductsBySkus(skusString, customerItems, 'en-CA')
    setLogInUserItems(items)
  }

  useEffect(() => {
    if (customerCart && loggingIn) {
      const skusString = customerCart?.items
        .map((item: { sku: string }) => item.sku)
        .join(',')
      getProducts(skusString, customerCart.items)
      setLoggingIn(false)
      if (localStorage.getItem('referral_token')) {
        setReferralRewardsQoute({
          quote_id: customerCart?.id,
          referral_link: localStorage.getItem('referral_token'),
        })
      }
    }
  }, [customerCart])

  useEffect(() => {
    if (country_regions) {
      let res = country_regions.available_regions.map((item: any) => {
        return { label: item.name, code: item.code, id: item.id }
      })

      setRegions(res)
    }
  }, [country_regions])

  useEffect(() => {
    regionApi(null)
    if (session?.isGuest) {
      setGuest(true)
      getCart()
      if (!shippingInformation?.postcode)
        setShippingInformation(defaultShipping)
      if (!billingInformation?.postcode) setBillingInformation(defaultShipping)
    } else if (session?.token) {
      setGuest(false)
      userApi(null)
      getCart()
    }
  }, [session])

  useEffect(() => {
    createGuest()
  }, [])

  useEffect(() => {
    if (createCart) {
      if (session?.token) {
        createCustomer()
        userApi(null)
      } else {
        createGuest()
      }
      setCreateCart(false)
    }
  }, [createCart])

  const createGuest = async () => {
    const initialSession = getSessionStore() || (await getSession())
    if (!initialSession?.token && !initialSession?.isGuest) {
      await createGuestCart(null)
    }
  }

  const createCustomer = async () => {
    await createCustomerCart(null)
    getCart()
  }

  const getCart = () => {
    if (session?.token) {
      getCustomerCart(null)
    } else if (session?.isGuest) {
      getGuestCart(null)
    }
    setEmpty(customerCart?.items.length === 0 || guestCart?.items.length === 0)
  }

  useEffect(() => {
    if (cartid && !session?.isGuest)
      signIn('credentials', {
        redirect: false,
        isGuest: true,
        cart: cartid,
      })
  }, [cartid])

  const resetAll = () => {
    setCartStatus(CART_STATUS.EMPTY)
    setSameAsShipping(true)
    setAddresses(null)
    setEmpty(true)
  }

  const getAddresses = () => {
    let shippingAddress =
      user.addresses.find(
        (address: any) => address['default_shipping'] != null,
      ) || user.addresses?.[0]
    let billingAddress =
      user.addresses.find(
        (address: any) => address['default_billing'] != null,
      ) || user.addresses?.[0]
    cartServices.setSelectedPostalCode(shippingAddress?.postcode)
    shippingAddress.extension_attributes = {
      address_type: SETTINGS.shipping.ADDRESS_TYPE,
    }
    setShippingInformation(shippingAddress ? shippingAddress : defaultShipping)
    setBillingInformation(billingAddress ? billingAddress : defaultShipping)

    return {
      shippingAddress,
      billingAddress,
    }
  }

  useEffect(() => {
    if (user?.addresses?.length) {
      setAddresses(getAddresses())
    }
  }, [user])
  return (
    <UserContext.Provider
      value={{
        regions,
        user,
        userApi,
        isGuest,
        cartId: isGuest ? guestCart : customerCart,
        cart,
        setCart,
        shippingInformation,
        billingInformation,
        setShippingInformation,
        setBillingInformation,
        sameAsShipping,
        setSameAsShipping,
        getCart,
        cartStatus,
        setCartStatus,
        isEmpty,
        setEmpty,
        resetAll,
        setCreateCart,
        cartLoader,
        setCartLoader,
        loggingIn,
        setLoggingIn,
        logInUserItems,
        setLogInUserItems,
        magConfig,
        setMagConfig,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider
