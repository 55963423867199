export function formatProductName(
  name: string | string[],
  brand: any,
  case_description: string,
  case_quantity: any,
) {
  let formattedName = ''

  if (brand && name && name.indexOf(String(brand)) === -1) {
    formattedName += `${String(brand).toLocaleLowerCase()} `
  }

  formattedName += `${String(name).toLocaleLowerCase()} `

  if (case_description) {
    formattedName += `(${case_description.toLocaleLowerCase()}) `
  }

  if (case_quantity) {
    formattedName += `${case_quantity} per case`
  }

  return formattedName.trim()
}
