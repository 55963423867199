export const LIMITS = {
  CATEGORIES_LIMITS: 18,
  PRODUCTS_LIMITS: 30,
  REFINED_PRODUCTS_LIMITS: 20,
  RELATED_PRODUCTS_LIMITS: 15,
  BEST_SELLER_PRODUCTS_LIMITS: 15,
  BEST_SELLER_GROCERY_PRODUCTS_LIMITS: 21,
  POPULAR_PRODUCTS_LIMITS: 14,
  POPULAR_PRODUCTS_TWO_LIMITS: 10,
  COOKIES_PRODUCTS_LIMITS: 15,
  CHIPS_PRODUCTS_LIMITS: 15,
  POPCORN_JERKY_PRODUCTS_LIMITS: 15,
  FRESH_VEGETABLES_PRODUCTS_LIMITS: 15,
  BRAND_PRODUCTS_LIMIT: 12,
}
